import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ApiCore } from 'src/app/config/api';
import { ToolsValidations } from 'src/app/core/tools/tools.validators';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ApiService } from '../../../core/http/api.service';
import * as moment from 'moment';
import { SessionService } from 'src/app/core/auth/session.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FileUpload } from 'primeng/fileupload';
import { ParseFlags } from '@angular/compiler';
import { IOperatorConfig } from 'src/app/models/Profile/OperatorInterfaces';
import { ProfileMenuService } from 'src/app/shared/services/profile-menu.service';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [MessageService]
})
export class ProfileComponent implements OnInit {

  items: MenuItem[] = [
    {label:"Información personal", icon:'pi pi-user', styleClass : "active"},
   //{label:"Cambiar imagen de perfil", icon:'pi pi-images', command:() => this.handleClick()},
    {label:"Cambiar nombre de usuario", icon:'pi pi-users', command:() => this.changeUserName()},
    {label:"Restablecer contraseña", icon:'pi pi-lock', command:() => this.restorePassword()}
  ];
  itemsSubMenu = [
    {label: "Lobby", icon: 'pi pi-fw pi-home',command: () => this.openProfile()},
    {label: "Cerrar Sesión", icon: 'pi pi-fw pi-sign-out',command: () => this.logout()}
  ];

  public selectedCountry:any =   //{ id:'clabcdo4k000yur7c0eem0noj', country: 'México', ladaCode: '(+52)', flagCode: 'mx' };
  {
    id: "clabcdo4k000yur7c0eem0noj",
    country: "México",
    ladaCode: "(+52)",
    flagCode: "mx",
    isActive: true,
    createdAt: "2022-11-10T17:26:30.493Z",
    updatedAt: "2022-11-10T17:23:12.321Z"
}

  public showRestore:boolean = false;
  public showChangeUserName:boolean = false;
  public showMissedInformation:boolean = false;
  public userProfile:any = [];
  public isValidBirthday:any = false;
  public messagePasswordError = "";
  public messageUserNameError = "";
  public messageSameUserNameError = "";
  public balance:any;
  public bonus:any;
  public totalBalance:any;
  public isValidUser= false;
  isExistError: any = false;
  isSamePassword: boolean = false;
  isValidCurrentPassword: boolean = false;

  public showCurrentPassword = false;
  public showNewPassword = false;
  public showConfirmPassword = false;

  generes_catalog: any[] = [];
  countries_code_catalog: any[] =[];
  peerVerification: boolean = false;
  peerVerification_line2: string = "";
  peerVerification_credits: number|null = null

  public userVrified: string = 'N';  //N-New P-In Progress C-Completed
  public hasCompleteInformation = false;
  showLoadSkeleton = false;

  public IsSocialMediaVisible = false;
  public IsBuyCreditsVisible = false;
  public NumberContact: string = '';
  public Operator: any;

  public currencyCode: string = '';
  public currencySymbol: string = '';

  public profileForm: FormGroup = this.formBuilder.group({
    userName: new FormControl(),
    mail: new FormControl(),
    firstName: new FormControl('',[Validators.required, Validators.minLength(3), Validators.maxLength(50),Validators.pattern(ToolsValidations.patternUserFullName)]),
    lastName: new FormControl('',[Validators.required, Validators.minLength(3), Validators.maxLength(50),Validators.pattern(ToolsValidations.patternUserFullName)]),
    birthday: new FormControl('',[Validators.required]),
    genere: new FormControl('',[Validators.nullValidator]),
    phoneNumber: new FormControl('',[Validators.minLength(6), Validators.maxLength(12)]),
    country:new FormControl("clabcdo4k000yur7c0eem0noj")
  });

  public userChangeForm: FormGroup = this.formBuilder.group({
    changeUserName: new FormControl('', [Validators.required, Validators.maxLength(16),Validators.minLength(5),Validators.pattern(ToolsValidations.patternUser)]),
    confirmChangeUserName: new FormControl()
  });

  public passwordChangeForm: FormGroup = this.formBuilder.group({
    currentPassword: new FormControl('', [Validators.required, Validators.pattern(ToolsValidations.patternPassword)]),
    newPassword: new FormControl('', [Validators.required, Validators.pattern(ToolsValidations.patternPassword)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.pattern(ToolsValidations.patternPassword)])
  });

  messageUserFullNameError: string = "";
  messageUserFullLastNameError: string = "";
  messagePhoneNumberError: string = "";
  operatorLogo: string | null ="";
  profilePicture: any;
  profilePictureEdit: any  = '../assets/images/pencil.png';

  showEditImage:boolean = false;
  userStatus: any;
  public balanceCredits: number= 0;
  public balanceBonus: number= 0;
  public loadingBalance: boolean= true;


  @ViewChild('fileInput') fileInput:FileUpload | undefined ;
  fileReadedDocument: any = null;
  isLandscape: boolean = true;
  isMobile = false;

  @HostListener('window:focus', ['$event'])
    onFocus(event: FocusEvent) {
        this.verifyValidToken();
    }

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private messageService: MessageService,
    private session: SessionService,
    private router: Router,
    private api: ApiService,
    private readonly profileMenu: ProfileMenuService,
    private deviceService: DeviceDetectorService
    ) {
      this.verifyValidToken();
      this.assignMenuItems()
      var ua = navigator.userAgent;
      this.isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));
    }

    assignMenuItems(): void {
      const items = [
        {label: "Lobby", icon: 'pi pi-fw pi-home'},
        {label: "Cerrar Sesión", icon: 'pi pi-fw pi-sign-out'}
      ]
      this.profileMenu.setData(items);
    }

  activeMenu(event:any) {
    let node;
    if (event.target.tagName === "A") {
      node = event.target;
    } else {
      node = event.target.parentNode;
    }
  }

  checkGameSession() {
    this.api.getGameSession(ApiCore.SESSION_GAMES).subscribe({
        next: (res) =>{
            console.log(res);
        },
        error: (e) =>{
            console.log(e)
            if(e.status === 403){
                this.auth.destroySession();
            }
        }
    });
}

  ngOnInit(): void {

    this.api.getCoorporateCasinoConfig().subscribe(res => {
      //console.log(res);
      this.operatorLogo = res.data.logoCasinoUrl;
      this.peerVerification = res.data.personalVerification;
      //this.peerVerification = true;
    });

    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    });
    this.LoadUserData();
    this.api.list(ApiCore.USER+"/wallet-balance").subscribe(res =>{
      this.balance =  res.data.balance;
      this.bonus =  res.data.bonus;
      this.balanceBonus = res.data.bonus;
      this.balanceCredits = res.data.balance;
      this.totalBalance =  res.data.balance;
      this.loadingBalance = false;
    }, error =>{
      this.messageService.add({key: 'tc',severity: 'error', summary: 'Error al cargar el balance del usuario' });
    });

    this.api.list(ApiCore.CATALOGS+"country-codes-catalog").subscribe(res => {
      //console.log(res)
      this.countries_code_catalog = res.data;
    });

    this.api.list(ApiCore.CATALOGS+"gender-catalog").subscribe(res => {
      this.generes_catalog = res.data;
      this.generes_catalog.splice(0,0,{id:null,name:'Selecciona tu género aquí',description:'Selecciona tu género aquí'})
    });

    //this.operatorLogo = this.session.getItem("operatorLogo");
    this.peerVerification = true;//TODO: Read this value from Operator API, Set as TRUE for testing purposes.
    this.peerVerification_credits = 120;     //TODO: Read this value from Operator API,
    this.translate.get('profile.personal_verification.disclaimer_ln2', { credits: this.peerVerification_credits}).subscribe((text:string) => {this.peerVerification_line2 = text});

    this.getOperatorCurrency();

    var inputElement = document.getElementById("upload-file");
    if(inputElement != null)
    {
      inputElement.onclick = function(event) {
      //  console.log(event)
      }
      inputElement.onblur = function(event) {
        //console.log(event)
      }
      inputElement.onabort = function(event) {
        //console.log(event)
      }
      inputElement.onchange = function(event) {
        //console.log(event)
      }

      inputElement.onclose = function(event) {
        //console.log(event)
      }
    }
    this.detectScreenOrientation();
    if(this.deviceService.browser === 'Safari' && this.isMobile) {
      window.matchMedia("(orientation: portrait)").addEventListener("change", () => this.detectScreenOrientation());
  }
  }

  @HostListener('window:orientationchange')
  onOrientationChange() {
      this.detectScreenOrientation();
  }
  detectScreenOrientation() {
    if(this.deviceService.browser === 'Safari') {
        const portrait = window.matchMedia("(orientation: portrait)").matches;
        if (portrait) {
            this.isLandscape = false;
        } else {
            this.isLandscape = true;
        }
        return;
    }
    const isScreenOrientationSupported = 'orientation' in window.screen && 'type' in window.screen.orientation;
    if (isScreenOrientationSupported) {
        const orientation = window.screen.orientation;
        console.log({orientation});
        if (orientation.type.includes('portrait')) {
            this.isLandscape = false;
        } else {
            this.isLandscape = true;
        }
    } else {
        const aspectRatio = window.innerWidth / window.innerHeight;
        if (aspectRatio > 1) {
            this.isLandscape = true;
        } else {
            this.isLandscape = false;
        }
    }
}

async verifyValidToken(){
  this.api.list(ApiCore.USER+"/me").subscribe({
    next: (res) => {
      console.log('data me-->',res.data)
      if(res.data.isPCPlayer){
        this.router.navigate(['/']);
      }
      this.userStatus = res.data.userStatus;
    },
    error: (e) => {
      console.error(e);
      if(e.statusCode === 403){
        this.auth.destroySession();
      }
    }
  });
}


  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  openProfile()
  {
      this.router.navigate(['/app']);
  }

  logout()
  {
      this.auth.logout().subscribe(res => {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['/app/home']));
      });
  }

  setValues()
  {
    this.profileForm.get("userName")?.setValue(this.userProfile.userName);
    this.profileForm.get("mail")?.setValue(this.userProfile.email);
    this.profileForm.get("firstName")?.setValue(this.userProfile.firstName);
    this.profileForm.get("lastName")?.setValue(this.userProfile.lastName);
    this.profileForm.get("birthday")?.setValue(moment.parseZone(this.userProfile.birthday).format("MM/DD/YYYY"));
    this.profileForm.get("genere")?.setValue(this.userProfile.genderCat);
    this.profileForm.get("phoneNumber")?.setValue(this.userProfile.phoneNumber);
    this.profileForm.get("country")?.setValue(this.userProfile.countryCode??this.selectedCountry);
    this.profilePicture = this.userProfile.profilePictureUrl ?? "../assets/images/imageProfile.png";
    this.hasCompleteInformation = this.profileForm.valid;
    this.profileForm.markAsPristine();
  }

  restorePassword(){
    this.passwordChangeForm.get("currentPassword")?.setValue("");
    this.passwordChangeForm.get("newPassword")?.setValue("");
    this.passwordChangeForm.get("confirmPassword")?.setValue("");
    this.showRestore = true;
    this.items = [
      {label:"Información personal", icon:'pi pi-user'},
      //{label:"Cambiar imagen de perfil", icon:'pi pi-images', command:() => this.handleClick()},
      {label:"Cambiar nombre de usuario", icon:'pi pi-users', command:() => this.changeUserName()},
      {label:"Restablecer contraseña", icon:'pi pi-lock',styleClass : "active", command:() => this.restorePassword()}
    ];
  }

  changeSelect(){
    this.selectedCountry = this.profileForm.get("country")?.value;
    console.log(this.selectedCountry)
  }

  changeUserName(){
    this.userChangeForm.get("changeUserName")?.setValue("");
    this.userChangeForm.get("confirmChangeUserName")?.setValue("");
    this.messageUserNameError = ""
    this.showChangeUserName = true;

    this.items = [
      {label:"Información personal", icon:'pi pi-user'},
      //{label:"Cambiar imagen de perfil", icon:'pi pi-images', command:() => this.handleClick()},
      {label:"Cambiar nombre de usuario", icon:'pi pi-users', styleClass : "active" ,command:() => this.changeUserName()},
      {label:"Restablecer contraseña", icon:'pi pi-lock', command:() => this.restorePassword()}
    ];
  }

  closeDialog()
  {
    this.showChangeUserName = false;
    this.showRestore = false;
    this.showMissedInformation = false;

    this.items = [
      {label:"Información personal", icon:'pi pi-user', styleClass : "active"},
      {label:"Cambiar nombre de usuario", icon:'pi pi-users', command:() => this.changeUserName()},
      {label:"Restablecer contraseña", icon:'pi pi-lock', command:() => this.restorePassword()}
    ];
  }
  BuyCredits() {
    if (this.userStatus == "Incompleto") {
      this.showMissedInformation = true;

    }
    else {
      this.IsBuyCreditsVisible = true;

      this.GetoperatorData();
    }
  }

  RetryCredits(){
    //this.showMissedInformation = true;
    this.IsBuyCreditsVisible =true;
    this.GetoperatorData();
  }

  validateUserFullName(){
    var user_full_name : string =this.profileForm.get("firstName")?.value.trim();
    if(user_full_name.length < 3)
      this.messageUserFullNameError = "Tu nombre debe tener de 3 a 30 caracteres.";
    else if(!RegExp(ToolsValidations.patternUserFullName).test(user_full_name))
      this.messageUserFullNameError = "Tu nombre solo puede tener letras y espacios.";
    else
      this.messageUserFullNameError = '';
  }

  validateUserLastName(){
    var last_name : string =this.profileForm.get("lastName")?.value.trim();
    if(last_name.length < 3 || last_name.length  > 50)
      this.messageUserFullLastNameError = "Tu apellido debe tener de 3 a 30 caracteres.";
    else if(!RegExp(ToolsValidations.patternUserFullName).test(last_name))
      this.messageUserFullLastNameError = "Tu apellido solo puede tener letras y espacios.";
    else
      this.messageUserFullLastNameError = '';
  }

  validatePhoneNumber(){
    var phoneNumber : string =this.profileForm.get("phoneNumber")?.value;
    if(phoneNumber.toString().length < 6 || phoneNumber.toString().length > 12)
      this.messagePhoneNumberError = "Tu teléfono debe tener de 6 a 12 dígitos.";
    else
      this.messagePhoneNumberError = '';
  }

  changeDate()
  {
    if(this.profileForm.get("birthday")?.value != "Invalid date")
    {
      var birthdayDate : string = this.profileForm.get("birthday")?.value;
      var date = moment(birthdayDate).add(18,'years');
      this.isValidBirthday = !date.isAfter(moment());
      //console.log(this.isValidBirthday)
    }
  }

  handleClick() {
    document.getElementById('upload-file')?.click();
  }

  addAttachment(fileInput: any) {
    this.showLoadSkeleton = true
    if(fileInput.target.files.length > 0){
      let fileReaded = fileInput.target.files[0];
      if(fileReaded?.size > 2000000){
        this.messageService.add({key: 'tc',severity: 'error', summary: 'La imagen no puede pesar más de 2MB' });
        this.showLoadSkeleton = false
        return
      }
      this.api.uploadImageProfile(ApiCore.USER_UPLOAD_IMAGE_PROFILE, fileReaded).subscribe({
        next: (response: any) => {
          console.log(response)
          this.messageService.add({key: 'tc',severity: 'success', summary: 'Tu imagen de perfil se ha actualizado satisfactoriamente' });
          this.LoadUserData();
        },
        error: (error: any) => {
          console.error(error)
          this.messageService.add({key: 'tc',severity: 'error', summary: 'Tu imagen de perfil no se ha podido actualizar' });
          this.showLoadSkeleton = false;
        },
        complete: () => {
          this.showLoadSkeleton = false;
        }
      });
      this.closeDialog();
    }else{
      this.showLoadSkeleton = false
    }
  }

  addDocument(fileInput: any) {
    this.fileReadedDocument = fileInput.target.files[0];
    this.closeDialog();
  }

  UploadDocument()
  {
    this.api.upload(ApiCore.USER_UPLOAD_PERSONAL_ID, this.fileReadedDocument).subscribe(
      (event: any) => {
        this.showPendingVerificationNotification();
        this.LoadUserData();
      }
    );
  }

  onDocumentUploadClick()
  {
    document.getElementById('upload-file-2')?.click()
  }

  saveData(){
    this.api.updateDirect(ApiCore.USER,{
      firstName:this.profileForm.get("firstName")?.value,
      lastName:this.profileForm.get("lastName")?.value,
      birthday:this.profileForm.get("birthday")?.value,
      phoneNumber:this.profileForm.get("phoneNumber")?.value,
      genderCatId:(this.profileForm.get("genere")?.value).id,
      countryCodeId:(this.profileForm.get("country")?.value).id

     }).subscribe(res =>{
      this.userProfile = res.data;
      this.messageService.add({key: 'tc',severity: 'success', summary: 'Información personal actualizada satisfactoriamente' });
      this.LoadUserData();
      this.profileForm.markAsPristine();
    },error => {
      this.profileForm.markAsPristine();
      this.messageService.add({key: 'tc',severity: 'error', summary: 'Error al actualizar la información personal' });
    });
  }

  myUploader(event: any) {
    //event.files == files to upload
    const file = event.files[0];
    this.api.upload(ApiCore.USER_UPLOAD_PERSONAL_ID, file).subscribe(
      (event: any) => {
          this.showPendingVerificationNotification();
          this.LoadUserData();
      }
      );
  }

  myUploaderImageProfile(event: any) {
    //event.files == files to upload
    const file = event.files[0];
    this.api.uploadImageProfile(ApiCore.USER_UPLOAD_IMAGE_PROFILE, file).subscribe(
      (event: any) => {
        this.LoadUserData();
      }
      );
  }

  LoadUserData()
  {
    this.showLoadSkeleton = true;
    this.api.list(ApiCore.USER+"/me").subscribe(res =>{

      this.userProfile = res.data;
      this.userVrified = 'N'
      if(this.userProfile.idDocumentUrl != null && this.userProfile.idDocumentSubmitDate != null && this.userProfile.isIdDocumentApproved && this.userProfile.verified)
        this.userVrified = 'C'
      else
        if(this.userProfile.idDocumentUrl != null && this.userProfile.idDocumentSubmitDate != null)
          this.userVrified = 'P'
         // this.userVrified = 'C'

      this.setValues();
      this.showLoadSkeleton = false;
    }, error =>{
      this.messageService.add({key: 'tc',severity: 'error', summary: 'Error al cargar la información del usuario' });
    });
  }

  showPendingVerificationNotification()
  {
    if(this.userVrified == 'P' )
    this.messageService.add({key: 'tc',severity: 'success', summary: 'Estamos procesando tu verificación. Cuando sea aprobada, te notificaremos por correo electrónico.' });
  }

  validateUserName(){
    console.log("er")
    this.messageUserNameError = ""
    var userName : string = this.userChangeForm.get("changeUserName")?.value;
    if(userName.length < 5 || userName.length > 16)
      this.messageUserNameError = "Tu usuario debe tener de 5 a 16 caracteres.";
    else if(!RegExp(ToolsValidations.patternUser).test(userName))
      this.messageUserNameError = "Tu usuario solo puede tener letras y números.";
    else
    {
      this.validateUsedUserName()
    }
    //this.validateSameUserName();

  }

  validateSameUserName(){
    this.messageSameUserNameError = ""
    var userName : string = this.userChangeForm.get("changeUserName")?.value;
    var userConfirmName : string = this.userChangeForm.get("confirmChangeUserName")?.value;

    if(userName != userConfirmName)
    {
      this.messageSameUserNameError = "Tu nombre de usuario no es igual.";
      this.userChangeForm.controls['confirmChangeUserName'].setErrors({'incorrect': true});
    }

  }

  validateUsedUserName()
  {

   /*  if(this.messageUserNameError != "")
    return; */

    var newUserName : string = this.userChangeForm.get("changeUserName")?.value;
    var userName : string = this.profileForm.get("userName")?.value;

    if(newUserName == userName)
    {
      this.messageUserNameError = "Tu nuevo nombre de usuario debe ser diferente al actual.";
      this.isValidUser = false;
      return;
    }
    this.auth.ExistUserName(newUserName).subscribe(res =>{
      this.messageUserNameError = ""
      this.isValidUser = true;

    },error =>{
      this.messageUserNameError = "Lo sentimos, ese usuario ya está en uso, prueba con otro.";
      this.isValidUser = false;
    });
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  updateUserName(){
    this.api.updateDirect("user/username",{
      userName:this.userChangeForm.get("changeUserName")?.value,
     }).subscribe(async res =>{
      this.messageService.add({key: 'tc',severity: 'success', summary: 'Tu nombre de usuario se ha actualizado satisfactoriamente' });
      await this.delay(1000);
      this.auth.logout().subscribe(res => {
        this.router.navigate(['/app/auth/login']);
      });
    },error => {
      this.messageService.add({key: 'tc',severity: 'error', summary: 'Error al actualizar la información personal' });
    });
  }

  updatePassword(){
    this.api.updateDirectPost("auth/change-password",{
      oldPassword:this.passwordChangeForm.get("currentPassword")?.value,
      newPassword:this.passwordChangeForm.get("newPassword")?.value,
     }).subscribe(async res =>{
      this.messageService.add({key: 'tc',severity: 'success', summary: 'Tu contraseña se ha actualizado satisfactoriamente' });
      await this.delay(1000);
      this.router.navigate(['/app/auth/login']);
      this.auth.logout();
    },error => {
      this.messageService.add({key: 'tc',severity: 'error', summary: 'Error al actualizar tu contraseña' });
    });
  }

  validatePassword(){
    var password : string = this.passwordChangeForm.get("newPassword")?.value;
    var currentPassword : string = this.passwordChangeForm.get("currentPassword")?.value;
    this.isExistError = true;
    this.messagePasswordError = "";
    if(password.length < 8)
      this.messagePasswordError = "Utiliza al menos 8 caracteres";
    else if(password.length > 16)
      this.passwordChangeForm.get("newPassword")?.setValue(password.substring(0,16));
    else if(!RegExp(ToolsValidations.patternLowerCase).test(password))
      this.messagePasswordError = "Utiliza al menos una letra minúscula.";
    else if(!RegExp(ToolsValidations.patternUpperCase).test(password))
      this.messagePasswordError = "Utiliza al menos una letra mayúscula.";
    else if(!RegExp(ToolsValidations.patternNumber).test(password))
      this.messagePasswordError = "Utiliza al menos una número.";
    else if(!RegExp(ToolsValidations.patternUser).test(password))
      this.messagePasswordError = "Tu contraseña no puede tener espacios ni caracteres especiales.";
    else if(password === currentPassword)
      this.messagePasswordError = "Tu nueva contraseña debe ser diferente a la actual.";
    else
      this.isExistError = false;
      if(this.passwordChangeForm.get("confirmPassword")?.value != ""){
        this.validateSamePassword();
      }
  }


  validateSamePassword()
  {
    var password = this.passwordChangeForm.get("newPassword")?.value;
    var confPassword = this.passwordChangeForm.get("confirmPassword")?.value;
    if(password === confPassword)
    {
      this.passwordChangeForm.get("confirmPassword")?.setErrors(null);
      this.isSamePassword = true;
    }
    else
    {
      this.passwordChangeForm.get("confirmPassword")?.setErrors({'incorrect': true});
      this.isSamePassword = false;
    }
  }

  validateCurrentPassword(event: any){
    const password = event.target.value;
    const email = this.userProfile.email;
    this.api.validateData('user/validate-current-password', {email, password}).subscribe({
      next: (result) => {
        if (result.data.passwordMatches) {
          this.passwordChangeForm.get("currentPassword")?.setErrors(null);
          this.isValidCurrentPassword = true;
        }else{
          this.passwordChangeForm.get("currentPassword")?.setErrors({'incorrect': true});
          this.isValidCurrentPassword = false;
        }
      }
    })

  }

  openImage()
  {
    document.getElementById('upload-file')?.click();
  }

  public closeCreditsDialog(event: boolean){
    this.IsBuyCreditsVisible = event;
  }

  GetoperatorData(){
    this.api.list(`${ApiCore.OPERATOR}/operator-config`).subscribe((responseOperator: IOperatorConfig)=>{
      if(responseOperator.statusCode != 200)
      {
        this.messageService.add({key: 'bc',severity: 'error', summary: 'Error loading data' });
        return;
      }
      this.Operator = responseOperator
    },(error) => {
      this.messageService.add({key: 'bc',severity: 'error', summary: JSON.stringify(error) });
    })
  }

  private getOperatorCurrency(): void {
    this.api.GetOperatorConfig().subscribe({
       next: (res) => {
         this.currencyCode = res.data.currencyCode;
         this.currencySymbol = res.data.currencySymbol;
       },
       error: (e) => {
         console.log(e);
       }
    });
  }
}
