import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router, private auth: AuthService) {}

  isLoggedIn: boolean = false;

  ngOnInit(): void {
    this.verifyLoggedIn();

    if (this.isLoggedIn) {
      this.router.navigate(['/app/home/profile']);
    } else {
      this.router.navigate(['/app/auth/login']);
    }
  }

  async verifyLoggedIn() {
    try {
      this.isLoggedIn = this.auth.isAuthenticated();
      //console.log('this.isLoggedIn', this.isLoggedIn);
    } catch (error) {
      this.isLoggedIn = false;
      console.error(error);
    }
    // if (this.isLoggedIn) {
    //   this.verifyValidToken();
    // }
  }
}
