<p-toolbar styleClass="w-full pl-6 pr-6 toolbar-container">
    <!-- Desktop view toolbar logo left -->
    <div class="p-toolbar-group-left mb-3">
       <div style='padding-top: 10px;' *ngIf="isMobile == false">
           <div *ngIf="showLoadSpin" style="max-width: 200px; max-height: 60px;" class="flex justify-content-center align-items-center animation-duration-300 ">
               <p-progressSpinner styleClass="h-3rem custom-spinner" strokeWidth="4"></p-progressSpinner>
           </div>
           <div class="mb-2 border-round-sm" style="max-width: 200px; max-height: 60px; overflow: hidden; box-shadow: 0px 10px 15px rgb(26, 26, 26);">
               <p-image (click)="redirectHome();"  [imageStyle]="{minHeight: '60px', minWidth: '200px',maxHeight: '60px', maxWidth: '200px'}" *ngIf="!showLoadSpin" [routerLink]="['/']" imageClass="pl-0 w-10 h-full border-round-sm fadein animation-duration-300 cursor-pointer"  [src]="logo ? logo : 'assets/images/no-photo-available.png'" alt="Image" [preview]="false"></p-image>
           </div>
       </div>
   </div>
   <!-- Desktop view toolbar buttons rigth -->
   <div class="p-toolbar-group-right">
       <div style='display: flex; align-items: center;' *ngIf="isMobile == false">
           <div *ngIf="isLoggedIn == false" style="width: 100%;" class="flex justify-content-between">
               <p-button [routerLink]="['../auth/signin']" styleClass="register p-element p-ripple p-button-outlined mr-6 mb-2 p-button-rounded p-button-raised fadein animation-duration-300" >
                   <span class="p-button-label w-full text-center" style="color: white;">{{'lobby.register' | translate}}</span>
               </p-button>
               <p-button label="{{'lobby.login' | translate}}" [routerLink]="['../auth/login']" styleClass="login p-element p-ripple mb-2 p-button-rounded p-button-raised fadein animation-duration-300"></p-button>
           </div>
           <!--Is LoggedIn--> 
           <div *ngIf="isLoggedIn == true" class="flex flex-row" >
               
               <app-wallet [showLoadSkeleton]="showLoadSkeleton" [buyCredits]="buyCredits" [totalBalance]="totalBalance"></app-wallet>
               <!-- Profile menu -->
               <div class="flex align-items-center m-0 p-0 ml-5 cursor-pointer" (click)="menu.toggle($event)">
                   <p-menu (click)="menu.toggle($event)" [style]="{'width':'200px', 'box-shadow' :'0px 10px 15px rgb(26, 26, 26)'}" #menu [popup]="true" [model]="items"></p-menu>
                   <div >
                    <div *ngIf="!showLoadSkeleton.profile" style="box-shadow: 0px 10px 15px rgb(26, 26, 26); border-radius: 100%;"><p-avatar  pBadge size="large" styleClass="fadein animation-duration-300 m-0 p-0 flex align-content-center" shape="circle" image={{profilePicture}}></p-avatar>
                    </div>
                    <p-skeleton *ngIf="showLoadSkeleton.profile" shape="circle" size="3rem" class="fadein animation-duration-200" ></p-skeleton>
                   </div>
                   <a class="hidden md:block" [style]="{'background': 'transparent'}" icon="pi pi-angle-down">
                    <i class="pi pi-angle-down" style="color: white;"></i>
                   </a>
                   
               </div>
               
           </div>
       </div>
   </div>
</p-toolbar>
<div class="w-full">
    <!-- <div class="h-10rem" style="background: radial-gradient(ellipse farthest-corner at 75% 40%, #CFD1F7, #496081 );"></div> -->
   <router-outlet></router-outlet>
</div>
<app-credits-info-component [wallet]="wallet" [showCreditsDialog]="IsBuyCreditsVisible" (onCloseCreditsDialog)="closeBuyCreditsDialog($event)"></app-credits-info-component>
<p-dialog appendTo ="body"  [style]="{width: '500px'}" [modal]="true" [draggable]="false" [(visible)]="showMissedInformation" (onHide)="closeDialog()" >
   <p-header >
     <div style="justify-content: center;flex-direction: column;display: flex; align-items: center;">
       <label class="header_title">{{'profile.dialogMissidFiles.header' | translate}}</label>
     </div>
   </p-header>
   <div style="justify-content: center; display: flex; flex-wrap: wrap;">
     <label class="content_text" style="margin-left: 10px;">
       <span class="content_text">Para &nbsp;</span><span style="font-weight:bold;">comprar o retirar&nbsp;</span><span>créditos, será necesario que completes la&nbsp;</span><span style="font-weight:bold;">Verificación de Identidad&nbsp;</span><span>de tu pérfil.</span>
     </label>
   </div>
   <p-footer>
     <div style="display: flex; justify-content:center;" >
       <button type="button" style="width: 40%;" pButton label="{{'profile.dialogMissidFiles.button' | translate}}" routerLink="../home/profile" (click)="closeDialog()"></button>
     </div>
     </p-footer>
 </p-dialog>
 <p-dialog appendTo ="body"  [style]="{width: '500px'}" [modal]="true" [draggable]="false" [(visible)]="showMissedInfoGame" [dismissableMask]="true" (onHide)="closeDialog()" >
   <p-header >
     <div style="justify-content: center;flex-direction: column;display: flex; align-items: center;">
       <label class="header_title">{{'lobby.dialogSessionGames.title' | translate}}</label>
     </div>
   </p-header>
   <div style="justify-content: center; display: flex; flex-wrap: wrap;">
     <label class="content_text" style="margin-left: 10px;">
       <span class="content_text">{{'lobby.dialogSessionGames.text1' | translate}}</span><br>
       <span class="content_text">{{'lobby.dialogSessionGames.text2' | translate}}</span>
     </label>
   </div>
   <p-footer>
     <div style="display: flex; justify-content:center;" >
       <button type="button" style="width: 40%;" pButton label="{{'lobby.dialogSessionGames.button' | translate}}" (click)="closeDialog()"></button>
     </div>
     </p-footer>
 </p-dialog>
<style>
   :host ::ng-deep .custom-spinner .p-progress-spinner-circle {
      animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
  }
  
  @keyframes custom-progress-spinner-color {
      100%,
      0% {
          stroke: #85838D;
      }
      40% {
          stroke: #85838D;
      }
      66% {
          stroke: #85838D;
      }
      80%,
      90% {
          stroke: #85838D;
      }
  }
  
  @keyframes custom-progress-spinner-dash {
      0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
      }
      50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
      }
      100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
      }
  }
</style>