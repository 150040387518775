import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ToolsValidations } from 'src/app/core/tools/tools.validators';
import { AuthService } from '../../../core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/auth/session.service';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  providers: [MessageService],
})
export class SigninComponent implements OnInit {
  submitted = false;
  sumbmitForm: any;
  checkConfAge: any;
  checkConfTerm: any;
  isSamePassword: any = false;
  isExistError: any = false;
  messagePasswordError = '';
  messageUserNameError = '';
  messageMailError = '';
  operatorLogo: any;
  public showTermsConditions: boolean = false;
  public showPrivacyPolicy: boolean = false;
  public showPassword = false;
  public showConfirmPassword = false;
  public isValidUser = false;
  public isValidMail = false;

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private session: SessionService,
    private readonly toast: ToastService
  ) {
    this.sumbmitForm = this.formBuilder.group({
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(ToolsValidations.patternMail),
        ],
      }),
      userName: new FormControl('', [
        Validators.required,
        Validators.maxLength(16),
        Validators.minLength(5),
        Validators.pattern(ToolsValidations.patternUser),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(ToolsValidations.patternPassword),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(ToolsValidations.patternPassword),
      ]),
      confirmAge: new FormControl(false, [Validators.required]),
      confirmAcceptTerms: new FormControl(false, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.i18nService.localeEvent.subscribe({
      next: () => {
        this.useTranslate();
      },
    });
    this.operatorLogo = this.session.getItem('operatorLogo');
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  submit() {
    var userName = this.sumbmitForm.get('userName')?.value;
    var email = this.sumbmitForm.get('email')?.value;
    var password = this.sumbmitForm.get('password')?.value;
    this.authService.signup(email, password, userName).subscribe(
      (res) => {
        this.toast.showSuccess('', 'Usuario registrado correctamente');
        this.router.navigate(['/app/auth/login']);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  checkConfirmAge() {
    this.checkConfAge = this.sumbmitForm.get('confirmAge')?.value;
  }

  checkConfirmAcceptTerms() {
    this.checkConfTerm = this.sumbmitForm.get('confirmAcceptTerms')?.value;
  }

  validateSamePassword() {
    var password = this.sumbmitForm.get('password')?.value;
    var confPassword = this.sumbmitForm.get('confirmPassword')?.value;
    if (password === confPassword) {
      this.sumbmitForm.get('confirmPassword')?.setErrors(null);
      this.isSamePassword = true;
    } else {
      this.sumbmitForm.get('confirmPassword')?.setErrors({ incorrect: true });
      this.isSamePassword = false;
    }
  }

  validateUserName() {
    var userName: string = this.sumbmitForm.get('userName')?.value;
    this.messageUserNameError = '';
    if (userName.length < 5 || userName.length > 16)
      this.messageUserNameError = 'Tu usuario debe tener de 5 a 16 caracteres.';
    else if (!RegExp(ToolsValidations.patternUser).test(userName))
      this.messageUserNameError =
        'Tu usuario solo puede tener letras y números.';
  }

  validatePassword() {
    var password: string = this.sumbmitForm.get('password')?.value;
    this.isExistError = true;
    this.messagePasswordError = '';
    if (password.length < 8)
      this.messagePasswordError = 'Utiliza al menos 8 caracteres';
    else if (password.length > 16)
      this.sumbmitForm.get('password').setValue(password.substring(0, 16));
    else if (!RegExp(ToolsValidations.patternLowerCase).test(password))
      this.messagePasswordError = 'Utiliza al menos una letra minúscula.';
    else if (!RegExp(ToolsValidations.patternUpperCase).test(password))
      this.messagePasswordError = 'Utiliza al menos una letra mayúscula.';
    else if (!RegExp(ToolsValidations.patternNumber).test(password))
      this.messagePasswordError = 'Utiliza al menos una número.';
    else if (!RegExp(ToolsValidations.patternUser).test(password))
      this.messagePasswordError =
        'Tu contraseña no puede tener espacios ni caracteres especiales.';
    else this.isExistError = false;
    if (this.sumbmitForm.get('confirmPassword')?.value != '')
      this.validateSamePassword();
  }

  validateUsedMail() {
    //console.log("validate mails")
    this.messageMailError = '';
    var mail: string = this.sumbmitForm.get('email')?.value;
    this.authService.ExistEmail(mail).subscribe({
      next: (res: any) => {
        this.messageMailError = '';
        this.isValidMail = true;
      },
      error: (err: any) => {
        this.isValidMail = false;
        if (err.status == 403)
          this.messageMailError =
            'Lo sentimos, ese correo ya está en uso, prueba con otro.';
      },
    });
  }

  validateUsedUserName() {
    if (this.messageUserNameError != '') return;

    var userName: string = this.sumbmitForm.get('userName')?.value;
    this.authService.ExistUserName(userName).subscribe({
      next: (res: any) => {
        this.messageUserNameError = '';
        this.isValidUser = true;
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          console.error('An error occurred:', err.error.message);
        } else {
          console.error(
            `Backend returned code: '${err.status}', message: '${err.message}'`
          );
        }
        this.messageUserNameError =
          'Lo sentimos, ese usuario ya está en uso, prueba con otro.';
        this.isValidUser = false;
      },
    });
  }
}
